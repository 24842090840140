import React, {useContext, useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {match, P} from "ts-pattern"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import {DealType} from "../deal.type"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import DealForm from "../form/form.component"
import {SubmitHandler} from "react-hook-form"
import {SinglePurposeVehicleSortOpenApi} from "../../../generated"
import {normalizeDeal} from "../deal.util"
import DealQuestions from "../../deal-question/questions.component"
import {
    ComponentState,
    ComponentStateErrorAction,
    ComponentStateErrorLoading,
    ComponentStateLoaded,
    ComponentStateLoading,
    ComponentStateLoadingWithData,
    ComponentStateSuccess
} from "../../../tech/state/state.type"
import {DealComponentData} from "../data.type"
import BackendValidationErrorsAlert from "../../../tech/validation/alert.component"
import DealEditButtons from "./buttons.component"

const DealEdit = () => {
    let { id } = useParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<ComponentState<DealComponentData>>(new ComponentStateLoading())

    useEffect(() => {
        const fetch = async () => {
            try {
                const [
                    fetchedDeal,
                    fetchedDealCategories,
                    fetchedDealGeographies,
                    fetchedDealGroups,
                    fetchedDocumentTemplates,
                    fetchedInvestorGroups,
                    fetchedManualReconciliationBankAccounts,
                    fetchedQuestions,
                    fetchedSinglePurposeVehicles,
                ] = await Promise.all([
                    fetchClient.dealApi.get(id!),
                    fetchClient.dealCategoryApi.getAll(),
                    fetchClient.dealGeographyApi.getAll(),
                    fetchClient.dealGroupApi.getAll(),
                    fetchClient.documentApi.getAll(),
                    fetchClient.investorGroupApi.getAll(),
                    fetchClient.reconciliationApi.getAllManualReconciliationBankAccounts(),
                    fetchClient.dealApi.getAllQuestionsForDeal(id!),
                    fetchClient.singlePurposeVehicleApi.getAllPaginated(SinglePurposeVehicleSortOpenApi.NameAscending),
                ])
                setState(new ComponentStateLoaded<DealComponentData>({
                    deal: fetchedDeal,
                    categories: fetchedDealCategories,
                    documentTemplates: fetchedDocumentTemplates,
                    geographies: fetchedDealGeographies,
                    groups: fetchedDealGroups,
                    investorGroups: fetchedInvestorGroups,
                    questions: fetchedQuestions,
                    manualReconciliationBankAccounts: fetchedManualReconciliationBankAccounts,
                    singlePurposeVehicles: fetchedSinglePurposeVehicles.elements,
                }))
            } catch (err) {
                console.error(err)
                setState(new ComponentStateErrorLoading())
            }
        }
        fetch()
    }, [fetchClient, id])

    const onSubmit: SubmitHandler<DealType> = async (deal) => {
        if (state.type !== "LOADED" && state.type !== "ERROR_ACTION" && state.type !== "SUCCESS") {
            throw new Error("Not allowed.")
        }
        setState(new ComponentStateLoadingWithData(state.data))
        const res = await fetchClient.dealApi.update(id!, normalizeDeal(
            deal,
            state.data.geographies,
            state.data.singlePurposeVehicles,
        ))
        match(res)
            .with(
                { type: "RESPONSE" },
                (r) => {
                    window.scrollTo({ top: 0 })
                    setState(new ComponentStateSuccess({
                        ...state.data,
                        deal: r.val
                    }))
                }
            )
            .with(
                { type: "RESPONSE_ERROR" },
                () => setState(new ComponentStateErrorAction(state.data))
            )
            .with(
                { type: "VALIDATION_ERRORS" },
                (res) => {
                    window.scrollTo({ top: 0 })
                    setState(new ComponentStateErrorAction(state.data, res.errors))
                }
            )
            .exhaustive()
    }

    return match(state)
        .with(
            P.union({ type: "LOADED" }, { type: "ERROR_ACTION" }, { type: "SUCCESS" }),
            (s) => (
                <Section>
                    <SectionHeading
                        title={`Edit deal: ${s.data.deal!.name.completeOnboarding}`}
                        button={<DealEditButtons deal={s.data.deal!}/>}
                    />
                    {s.type === "ERROR_ACTION" && s.errors && <BackendValidationErrorsAlert errors={s.errors}/>}
                    {s.type === "ERROR_ACTION" && !s.errors && (
                        <Alert
                            type={AlertType.ERROR}
                            text="Failed to save."
                        />
                    )}
                    {s.type === "SUCCESS" && (
                        <Alert
                            type={AlertType.SUCCESS}
                            text="Saved successfully."
                        />
                    )}
                    <DealForm
                        deal={s.data.deal}
                        dealCategories={s.data.categories}
                        dealGeographies={s.data.geographies}
                        dealGroups={s.data.groups}
                        documentTemplates={s.data.documentTemplates}
                        investorGroups={s.data.investorGroups}
                        manualReconciliationBankAccounts={s.data.manualReconciliationBankAccounts}
                        singlePurposeVehicles={s.data.singlePurposeVehicles}
                        onSubmit={onSubmit}
                        submitText="Save deal"
                    />
                    <DealQuestions
                        dealId={s.data.deal!.id!}
                        questions={s.data.questions!}
                    />
                </Section>
            )
        )
        .with(
            { type: "ERROR_LOADING" },
            () => (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load."
                />
            )
        )
        .with(
            P.union({ type: "LOADING" }, { type: "LOADING_WITH_DATA" }),
            () => <LoadingDots/>
        )
        .exhaustive()
}

export default DealEdit