import {DealInvestorGroupOpenApi} from "../../../generated"
import {DealInvestorGroupType} from "./deal-investor-group.type"
import {mapDealFeeStructureToOpenApi, mapOpenApiToDealFeeStructure} from "../fee/deal-fee-structure.mapper"

export function mapOpenApiToDealInvestorGroup(group: DealInvestorGroupOpenApi): DealInvestorGroupType {
    return {
        id: group.id,
        sequentialId: group.sequentialId,
        name: group.name,
        description: group.description,
        feeStructure: group.feeStructure && mapOpenApiToDealFeeStructure(group.feeStructure)
    }
}

export function mapDealInvestorGroupToOpenApi(group: DealInvestorGroupType): DealInvestorGroupOpenApi {
    return {
        id: group.id,
        sequentialId: group.sequentialId,
        name: group.name,
        description: group.description,
        feeStructure: group.feeStructure && mapDealFeeStructureToOpenApi(group.feeStructure)
    }
}