import React, {useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import {SyndicateType} from "../syndicate.type"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import formStyles from "../../../tech/form/form.module.css"
import {SubmitHandler, useForm} from "react-hook-form"
import {SyndicateInvitationType} from "./invitation.type"
import BackendValidationErrorsAlert from "../../../tech/validation/alert.component"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import FormError from "../../../tech/form/error.component"
import {SYNDICATE_MEMBERS_INVITATIONS} from "../../../paths"
import {normalizeSyndicateInvitation} from "./invitation.util"
import {ArrowLeftCircleIcon} from "@heroicons/react/16/solid"
import {DealType} from "../../deal/deal.type"


const SyndicateInvitationNew = () => {
    let { syndicateId } = useParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const navigate = useNavigate()
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR_ACTION" | "ERROR_LOADING" | "SUCCESS">("LOADING")
    const [actionErrors, setActionErrors] = useState<string[]>([])
    const [syndicate, setSyndicate] = useState<SyndicateType>()
    const [dealsOfSyndicate, setDealsOfSyndicate] = useState<DealType[]>()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SyndicateInvitationType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                const [
                    fetchedDeals,
                    fetchedSyndicate
                ] = await Promise.all([
                    fetchClient.syndicateApi.getAllDeals(syndicateId!),
                    fetchClient.syndicateApi.get(syndicateId!)
                ])
                setDealsOfSyndicate(fetchedDeals)
                setSyndicate(fetchedSyndicate)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR_LOADING")
            }
        }
        fetch()
    }, [fetchClient, syndicateId])

    const onSubmit: SubmitHandler<SyndicateInvitationType> = async (invitation) => {
        setState("LOADING")
        const res = await fetchClient.syndicateApi.createNewInvitation(normalizeSyndicateInvitation(invitation, syndicate!))
        if (res.type === "RESPONSE") setState("SUCCESS")
        if (res.type === "RESPONSE_ERROR") setState("ERROR_ACTION")
        if (res.type === "VALIDATION_ERRORS") {
            setActionErrors(res.errors)
            setState("ERROR_ACTION")
        }
    }

    return (
        <Section>
            <SectionHeading title={`Invite someone to ${syndicate?.name ? syndicate.name : syndicateId}`}/>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR_ACTION" && (
                <BackendValidationErrorsAlert
                    text="Failed to create invitation."
                    errors={actionErrors}
                />
            )}
            {(state === "LOADED" || state === "ERROR_ACTION") && dealsOfSyndicate && syndicate && (
                <form
                    className={formStyles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    {dealsOfSyndicate.some(d => !d.published) && (
                        <Alert
                            type={AlertType.WARNING}
                            text={(
                                <>
                                    <strong>Not all of the deals assigned to the syndicate are published yet.</strong> Sending an invitation now does not make sense, as the investor won't be able to see the deal on the platform.
                                </>
                            )}
                        />
                    )}
                    <label>
                        <span>Email</span>
                        <input
                            type="text"
                            placeholder="Email"
                            {...register("email", {
                                required: "Email is required."
                            })}
                        />
                        <FormError field="email" errors={errors}/>
                    </label>

                    <label>
                        <span>Target Investor Group</span>
                        <select
                            {...register("targetInvestorGroup.id", {
                                required: "Target Investor Group is required."
                            })}
                        >
                            {syndicate.investorGroups.map((investorGroup, index) => (
                                <option
                                    value={investorGroup.id}
                                    key={`investor-group-${index + 1}`}
                                >
                                    {investorGroup.name} ({investorGroup.id})
                                </option>
                            ))}
                        </select>
                        <FormError field="targetInvestorGroup.id" errors={errors}/>
                    </label>

                    <button
                        type="submit"
                        className={formStyles.submitButton}
                    >
                        {dealsOfSyndicate.some(d => !d.published) ? (
                            <>I ignore the warning! Create &amp; send invitation anyway</>
                        ) : (
                            <>Create &amp; send invitation</>
                        )}
                    </button>
                </form>
            )}
            {state === "ERROR_LOADING" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load syndicate."
                />
            )}
            {state === "SUCCESS" && (
                <>
                    <Alert
                        type={AlertType.SUCCESS}
                        text="Inivitation successfully created and sent."
                    />
                    <Button
                        title="Go to invitation overview"
                        style={ButtonStyle.SECONDARY}
                        type="button"
                        icon={{
                            elem: (
                                <ArrowLeftCircleIcon
                                    width={16}
                                    height={16}
                                    style={{ marginTop: "1px", marginRight: "6px" }}
                                />
                            ),
                            left: true
                        }}
                        onClick={() => navigate(SYNDICATE_MEMBERS_INVITATIONS(syndicateId!))}
                    />
                </>
            )}
        </Section>
    )
}

export default SyndicateInvitationNew