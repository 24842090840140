import React, {useContext, useEffect, useState} from "react"
import {Link, useNavigate, useParams} from "react-router-dom"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import SyndicateForm from "./form.component"
import {SubmitHandler} from "react-hook-form"
import {SYNDICATE_DELETE, SYNDICATE_INVITATION_NEW, SYNDICATE_MEMBERS_INVITATIONS} from "../../paths"
import Button from "../../tech/button/button.component"
import {ButtonStyle} from "../../tech/button/style.enum"
import {normalizeValues} from "../../tech/form/form.util"
import {SyndicateType} from "./syndicate.type"
import {InvestorGroupType} from "../investor-group/investor-group.type"
import {UserType} from "../user/user.type"
import {UserSortOpenApi} from "../../generated"
import styles from "./edit.module.sass"

const SyndicateEdit = () => {
    let { id } = useParams()
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR" | "SUCCESS">("LOADING")
    const [syndicate, setSyndicate] = useState<SyndicateType>()
    const [investorGroups, setInvestorGroups] = useState<InvestorGroupType[]>([])
    const [managers, setManagers] = useState<UserType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const [
                    fetchedSyndicate,
                    fetchedInvestorGroups,
                    fetchedManagers,
                ] = await Promise.all([
                    fetchClient.syndicateApi.get(id!),
                    fetchClient.investorGroupApi.getAll(),
                    fetchClient.userApi.getAllPaginated(
                        UserSortOpenApi.CreatedAscending,
                        { role: "SYNDICATE_MANAGER" }
                    ),
                ])
                setSyndicate(fetchedSyndicate)
                setInvestorGroups(fetchedInvestorGroups)
                setManagers(fetchedManagers.elements)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const onSubmit: SubmitHandler<SyndicateType> = async (updatedSyndicate) => {
        setState("LOADING")
        try {
            setSyndicate(await fetchClient.syndicateApi.update(id!, normalizeValues(updatedSyndicate)))
            window.scrollTo({ top: 0 })
            setState("SUCCESS")
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Unknown error."
                />
            )}
            {state === "SUCCESS" && (
                <Alert
                    type={AlertType.SUCCESS}
                    text="Saved successfully."
                />
            )}
            {(state === "LOADED" || state === "ERROR" || state === "SUCCESS") && syndicate && (
                <Section>
                    <SectionHeading
                        title={`Edit syndicate: ${syndicate.name}`}
                        button={
                            <div className={styles.buttons}>
                                <Button
                                    title="Invite someone"
                                    style={ButtonStyle.SECONDARY}
                                    onClick={() => navigate(SYNDICATE_INVITATION_NEW(id!))}
                                    type="button"
                                />
                                <Button
                                    title="Delete"
                                    style={ButtonStyle.DELETE}
                                    onClick={() => navigate(SYNDICATE_DELETE(id!))}
                                    type="button"
                                />
                            </div>
                        }
                    />
                    <SyndicateForm
                        syndicate={syndicate}
                        investorGroups={investorGroups}
                        managers={managers}
                        onSubmit={onSubmit}
                        submitText="Save syndicate"
                    />
                    <div className={styles.memberAndInvitations}>
                        <Link to={SYNDICATE_MEMBERS_INVITATIONS(id!)}>
                            &rarr; Members & Invitations
                        </Link>
                    </div>
                </Section>
            )}
        </>
    )
}

export default SyndicateEdit