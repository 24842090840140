import React, {FunctionComponent, useEffect, useState} from "react"
import FileUpload from "./upload/upload.component"
import FileSelect, {FileSelectSearchType} from "./select/select.component"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {XMarkIcon} from "@heroicons/react/20/solid"
import {FolderArrowDownIcon, PlusIcon} from "@heroicons/react/16/solid"
import {MimeTypeOpenApi} from "../../../generated"
import {FileType} from "../file.type"
import {FileUploadSelectFormValueType} from "./upload-select.type"
import styles from "./upload-select.module.sass"
import FileUploadCurrentFilePreview from "./current-file/current-file.component"

type FileUploadSelectProps = {
    accept: MimeTypeOpenApi[]
    currentFile?: FileUploadSelectFormValueType
    filesProvider: (searchType: FileSelectSearchType, searchValue: string) => Promise<FileType[]>
    setFormValue: (value: FileUploadSelectFormValueType) => void
}

const FileUploadSelect: FunctionComponent<FileUploadSelectProps> =({
    accept,
    currentFile,
    filesProvider,
    setFormValue
}) => {
    const [active, setActive] = useState<"UPLOAD" | "SELECT">("UPLOAD")
    const [opened, setOpened] = useState<boolean>(false)

    function open() {
        document.getElementsByTagName("body")[0].style.overflow = "hidden"
        setOpened(true)
    }

    function close() {
        document.getElementsByTagName("body")[0].style.overflow = "scroll"
        setOpened(false)
    }

    useEffect(() => {
        return () => {
            document.getElementsByTagName("body")[0].style.overflow = "scroll"
        }
    }, [])

    return (
        <>
            <div className={styles.upload}>
                <FileUploadCurrentFilePreview currentFile={currentFile}/>
                <Button
                    title={currentFile ? <>Replace&nbsp;current&nbsp;file</> : <>Upload&nbsp;or&nbsp;select&nbsp;a&nbsp;file</>}
                    style={ButtonStyle.SECONDARY}
                    type="button"
                    onClick={open}
                    icon={{
                        elem: currentFile ? <FolderArrowDownIcon className="w-4 h-4 mr-1"/> : <PlusIcon className="w-4 h-4 mr-1"/>,
                        left: true
                    }}
                    small
                />
            </div>
            {opened && (
                <>
                    <div className={styles.overlay}>
                        <div className={styles.close}>
                            <XMarkIcon
                                className="w-10 h-10 text-teal-400"
                                onClick={close}
                            />
                        </div>
                    </div>
                    <div className={styles.uploadSelect}>
                        <div>
                            <div className={styles.head}>
                                <div
                                    className={active === "UPLOAD" ? styles.active : ""}
                                    onClick={() => setActive("UPLOAD")}
                                >
                                    Upload
                                </div>
                                <div
                                    className={active === "SELECT" ? styles.active : ""}
                                    onClick={() => setActive("SELECT")}
                                >
                                    Select
                                </div>
                            </div>
                            <div className={styles.body}>
                                {active === "UPLOAD" ? (
                                    <FileUpload
                                        accept={accept}
                                        close={close}
                                        setFormValue={setFormValue}
                                    />
                                ) : (
                                    <FileSelect
                                        close={close}
                                        filesProvider={filesProvider}
                                        setFormValue={setFormValue}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default FileUploadSelect