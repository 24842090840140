import React, {useContext, useEffect, useState} from "react"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {INVESTOR_DELETE} from "../../../paths"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import {useNavigate, useParams} from "react-router-dom"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import {SubmitHandler} from "react-hook-form"
import {InvestorType, InvestorWithDetailsType} from "../investor.type"
import InvestorForm from "../form.component"
import {DealGroupType} from "../../deal-group/deal-group.type"
import styles from "./edit.module.sass"
import SubscriptionSummary from "../../subscription/summary.component"
import {normalizeValues} from "../../../tech/form/form.util"

const InvestorEdit = () => {
    let { id } = useParams()
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR" | "SUCCESS">("LOADING")
    const [investorWithDetails, setInvestorWithDetails] = useState<InvestorWithDetailsType>()
    const [dealGroups, setDealGroups] = useState<DealGroupType[]>([])
    const [investorGroups, setInvestorGroups] = useState<DealGroupType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const [fetchedInvestor, fetchedDealGroups, fetchedInvestorGroups] = await Promise.all([
                    fetchClient.investorApi.get(id!),
                    fetchClient.dealGroupApi.getAll(),
                    fetchClient.investorGroupApi.getAll()
                ])
                setInvestorWithDetails(fetchedInvestor)
                setDealGroups(fetchedDealGroups)
                setInvestorGroups(fetchedInvestorGroups)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const onSubmit: SubmitHandler<InvestorType> = async (investor) => {
        setState("LOADING")
        try {
            setInvestorWithDetails(await fetchClient.investorApi.update(id!, normalizeValues(investor)))
            window.scrollTo({ top: 0 })
            setState("SUCCESS")
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    const firstName = investorWithDetails?.investor.person?.firstName
    const lastName = investorWithDetails?.investor.person?.lastName

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Unknown error."
                />
            )}
            {state === "SUCCESS" && (
                <Alert
                    type={AlertType.SUCCESS}
                    text="Saved successfully."
                />
            )}
            {(state === "LOADED" || state === "ERROR" || state === "SUCCESS") && investorWithDetails && (
                <Section>
                    <SectionHeading
                        title={`Edit investor: ${firstName && lastName ? firstName + " " + lastName : investorWithDetails.investor.id}`}
                        button={<Button
                            title="Delete"
                            style={ButtonStyle.DELETE}
                            onClick={() => navigate(INVESTOR_DELETE(id!))}
                            type="button"
                        />}
                    />
                    <InvestorForm
                        investor={investorWithDetails.investor}
                        dealGroups={dealGroups}
                        investorGroups={investorGroups}
                        onSubmit={onSubmit}
                        submitText="Save investor"
                    />

                    <div className={styles.subscription}>
                        <h3>Subscription</h3>
                        <SubscriptionSummary subscription={investorWithDetails.subscription}/>
                    </div>
                </Section>
            )}
        </>
    )
}

export default InvestorEdit