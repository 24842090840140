import React, {useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import FaqEntryForm from "./form.component"
import {SubmitHandler} from "react-hook-form"
import {FAQ_ENTRY_DELETE} from "../../paths"
import Button from "../../tech/button/button.component"
import {ButtonStyle} from "../../tech/button/style.enum"
import {normalizeValues} from "../../tech/form/form.util"
import {FaqEntryType} from "./faq-entry.type"

const FaqEntryEdit = () => {
    let { id } = useParams()
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR" | "SUCCESS">("LOADING")
    const [faqEntry, setFaqEntry] = useState<FaqEntryType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                setFaqEntry(await fetchClient.faqEntryApi.get(id!))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const onSubmit: SubmitHandler<FaqEntryType> = async (faqEntry) => {
        setState("LOADING")
        try {
            setFaqEntry(await fetchClient.faqEntryApi.update(id!, normalizeValues(faqEntry)))
            window.scrollTo({ top: 0 })
            setState("SUCCESS")
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Unknown error."
                />
            )}
            {state === "SUCCESS" && (
                <Alert
                    type={AlertType.SUCCESS}
                    text="Saved successfully."
                />
            )}
            {(state === "LOADED" || state === "ERROR" || state === "SUCCESS") && faqEntry && (
                <Section>
                    <SectionHeading
                        title={`Edit FAQ entry: ${faqEntry.title}`}
                        button={<Button
                            title="Delete"
                            style={ButtonStyle.DELETE}
                            onClick={() => navigate(FAQ_ENTRY_DELETE(id!))}
                            type="button"
                        />}
                    />
                    <FaqEntryForm
                        faqEntry={faqEntry}
                        onSubmit={onSubmit}
                        submitText="Save FAQ entry"
                    />
                </Section>
            )}
        </>
    )
}

export default FaqEntryEdit