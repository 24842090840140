import React, {FunctionComponent} from "react"
import {SubmitHandler, useForm} from "react-hook-form"
import formStyles from "../../tech/form/form.module.css"
import FormError from "../../tech/form/error.component"
import {UserType} from "./user.type"
import {UserRoleOpenApi} from "../../generated"

type UserFormProps = {
    user: UserType
    onSubmit: SubmitHandler<UserType>
    submitText: string
}

const UserForm: FunctionComponent<UserFormProps> = ({ user, onSubmit, submitText }) => {
    const { register, handleSubmit, formState: { errors } } = useForm<UserType>({
        defaultValues: user
    })

    return (
        <form
            className={formStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <input type="hidden" {...register("id")} />
            <input type="hidden" {...register("sequentialId")} />
            <input type="hidden" {...register("userId")} />
            <input type="hidden" {...register("email")} />

            {user && (
                <label>
                    <span>ID:</span> {user.id}<br/>
                </label>
            )}

            <label>
                <span>Role</span>
                <select {...register("role")}>
                    {Object.entries(UserRoleOpenApi).map((c, index) => (
                        <option
                            value={c[1]}
                            key={index}
                        >
                            {c[1]}
                        </option>
                    ))}
                </select>
                <FormError field="role" errors={errors} />
            </label>

            <button
                type="submit"
                className={formStyles.submitButton}
            >
                {submitText}
            </button>
        </form>
    )
}

export default UserForm