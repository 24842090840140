import React, {FunctionComponent} from "react"
import tableStyles from "../../tech/table/table.module.css"
import {InvestmentType} from "./investment.type"
import {mapCurrencyToString} from "../currency/currency.mapper"
import TableAction from "../../tech/link/action.component"
import {DEAL, INVESTOR} from "../../paths"
import {getInvestorName} from "../investor/investor.util"
import {formatDatetime} from "../../tech/format/format.util"

type InvestmentTableProps = {
    investment: InvestmentType
}

const InvestmentTable: FunctionComponent<InvestmentTableProps> = ({ investment }) => {
    return (
        <div className={tableStyles.tableWrapper}>
            <table className={tableStyles.table}>
                <tbody>
                <tr>
                    <td><strong>Id</strong></td>
                    <td>{investment.id}</td>
                </tr>
                <tr>
                    <td><strong>Created</strong></td>
                    <td>{formatDatetime(investment.created)}</td>
                </tr>
                <tr>
                    <td><strong>Amount</strong></td>
                    <td>
                        <strong>Amount:</strong> {investment.amountFormatted}<br/>
                        <strong>With setup fee:</strong> {investment.amountWithPlacementFeeFormatted}<br/>
                        <strong>With setup + processing fee:</strong> {investment.amountWithPlacementFeeAndExchangeFeeFormatted}<br/>
                        <strong>With setup + processing fee (exchanged):</strong> {investment.amountWithPlacementFeeAndExchangeFeeAtExchangeRateFormatted}<br/>
                    </td>
                </tr>
                <tr>
                    <td><strong>Currency</strong></td>
                    <td>
                        <strong>Deal:</strong> {mapCurrencyToString(investment.deal.currency)}<br/>
                        <strong>Selected:</strong> {investment.currency ? mapCurrencyToString(investment.currency) : <i>not yet selected</i>}
                    </td>
                </tr>
                <tr>
                    <td><strong>Setup fee:</strong></td>
                    <td>{investment.fees.placement.percent} % ({investment.fees.placement.getAmountFormatted(investment.deal.currency)})</td>
                </tr>
                <tr>
                    <td><strong>Processing fee:</strong></td>
                    <td>
                        {investment.deal.currency === investment.currency
                            ? "-"
                            : investment.fees.exchange !== undefined
                            ? `${investment.fees.exchange.percent} % (${investment.fees.exchange!.getAmountFormatted(investment.deal.currency)})`
                            : "not yet set"}
                    </td>
                </tr>
                {investment.exchangeRate && (
                    <tr>
                        <td><strong>Exchange rate:</strong></td>
                        <td>
                            <>
                                {investment.exchangeRate.rate}{" "}
                                ({formatDatetime(investment.exchangeRate.date, true)})
                            </>
                        </td>
                    </tr>
                )}
                <tr>
                    <td><strong>Agreed</strong></td>
                    <td>{investment.agreed ? "yes" : "no"}</td>
                </tr>
                <tr>
                    <td><strong>Signed</strong></td>
                    <td>{investment.signed ? "yes" : "no"}</td>
                </tr>
                <tr>
                    <td><strong>Payment instructions sent</strong></td>
                    <td>{investment.paymentInstructionsSent ? "yes" : "no"}</td>
                </tr>
                <tr>
                    <td><strong>Paid</strong></td>
                    <td>{investment.paid ? "yes" : "no"}</td>
                </tr>
                <tr>
                    <td><strong>Deal</strong></td>
                    <td>
                        <i>{investment.deal.name.completeOnboarding}</i>
                        <TableAction
                            text={"Go to deal"}
                            link={DEAL(investment.deal.id!)}
                        />
                    </td>
                </tr>
                <tr>
                    <td><strong>Investor</strong></td>
                    <td>
                        <i>{getInvestorName(investment.investor)}</i>
                        <TableAction
                            text={"Go to investor"}
                            link={INVESTOR(investment.investor.id!)}
                        />
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default InvestmentTable