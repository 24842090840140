import React, {useContext, useEffect, useState} from "react"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import Button from "../../tech/button/button.component"
import {ButtonStyle} from "../../tech/button/style.enum"
import {WAITLIST_ENTRY_DELETE} from "../../paths"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import {useNavigate, useParams} from "react-router-dom"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {SubmitHandler} from "react-hook-form"
import WaitlistEntryForm from "./form.component"
import {WaitlistEntryType} from "./waitlist.type"
import {normalizeValues} from "../../tech/form/form.util"

const WaitlistEntryEdit = () => {
    let { id } = useParams()
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR" | "SUCCESS">("LOADING")
    const [waitlistEntry, setWaitlistEntry] = useState<WaitlistEntryType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                setWaitlistEntry(await fetchClient.waitlistApi.get(id!))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const onSubmit: SubmitHandler<WaitlistEntryType> = async (waitlistEntry) => {
        setState("LOADING")
        try {
            setWaitlistEntry(await fetchClient.waitlistApi.update(id!, normalizeValues(waitlistEntry)))
            window.scrollTo({ top: 0 })
            setState("SUCCESS")
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Unknown error."
                />
            )}
            {state === "SUCCESS" && (
                <Alert
                    type={AlertType.SUCCESS}
                    text="Saved successfully."
                />
            )}
            {(state === "LOADED" || state === "ERROR" || state === "SUCCESS") && waitlistEntry && (
                <Section>
                    <SectionHeading
                        title={`Edit waitlist entry: ${waitlistEntry.id}`}
                        button={<Button
                            title="Delete"
                            style={ButtonStyle.DELETE}
                            onClick={() => navigate(WAITLIST_ENTRY_DELETE(id!))}
                            type="button"
                        />}
                    />
                    <WaitlistEntryForm
                        onSubmit={onSubmit}
                        waitlistEntry={waitlistEntry}
                        submitText="Save waitlist entry"
                    />
                </Section>
            )}
        </>
    )
}

export default WaitlistEntryEdit