import React, {useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {SubmitHandler} from "react-hook-form"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import {CompanyContactType} from "./contact.type"
import {normalizeValues} from "../../../tech/form/form.util"
import {COMPANY_CONTACT_DELETE} from "../../../paths"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import {AlertType} from "../../../tech/alert/type.enum"
import Alert from "../../../tech/alert/alert.component"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import CompanyContactForm from "./form.component"

const CompanyContactEdit = () => {
    let { id } = useParams()
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR" | "SUCCESS">("LOADING")
    const [contact, setContact] = useState<CompanyContactType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                setContact(await fetchClient.companyContactApi.get(id!))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const onSubmit: SubmitHandler<CompanyContactType> = async (contact) => {
        setState("LOADING")
        try {
            setContact(await fetchClient.companyContactApi.update(id!, normalizeValues(contact)))
            window.scrollTo({ top: 0 })
            setState("SUCCESS")
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Unknown error."
                />
            )}
            {state === "SUCCESS" && (
                <Alert
                    type={AlertType.SUCCESS}
                    text="Saved successfully."
                />
            )}
            {(state === "LOADED" || state === "ERROR" || state === "SUCCESS") && contact && (
                <Section>
                    <SectionHeading
                        title={`Edit company contact: ${contact.name}`}
                        button={<Button
                            title="Delete"
                            style={ButtonStyle.DELETE}
                            onClick={() => navigate(COMPANY_CONTACT_DELETE(id!))}
                            type="button"
                        />}
                    />
                    <CompanyContactForm
                        contact={contact}
                        onSubmit={onSubmit}
                        submitText="Save company contact"
                    />
                </Section>
            )}
        </>
    )
}

export default CompanyContactEdit